import React, { useState } from "react";
import java from "../../Assets/javaDevelopment.png";
import web from "../../Assets/webDevelopment.png";
import python from "../../Assets/pythonProgramming.png";
import { Link } from "react-router-dom";

const MyLearning = () => {
  return (
    <>
      <div className="container-fluid">
        <div>
          <h3>My Learning</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-3 p-3">
          <div className="card">
            <img src={java} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Java for beginners..</h5>
            </div>
            <div className="card-body">
              <Link to="/java">Deatails & Lesson</Link>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="card">
            <img src={python} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Python for beginners..</h5>
            </div>
            <div className="card-body">
              <Link to="/python">Deatails & Lesson</Link>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="card">
            <img src={web} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Cyber security for beginners..</h5>
            </div>
            <div className="card-body">
              <Link to="/cyber">Deatails & Lesson</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyLearning;
