// import React from 'react'
// import './Courses.css'
import Card from "react-bootstrap/Card";
// import Navbar from '../Navbar/Navbar';
import fiver from "../../Assets/Fiverr.png";
import web from "../../Assets/webDevelopment.png";
import java from "../../Assets/javaDevelopment.png";
import python from "../../Assets/pythonProgramming.png";
import cyber from "../../Assets/Cybersecurity.png";
import uiux from "../../Assets/UiUX.png";

const Courses = () => {
  return (
    <>
      <div className="container-fluid">
        <div>
          <h3>Our Courses</h3>
        </div>

        <div className="row">
          <div className="col-3  p-3">
            <div className="card">
              <img src={java} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Java for beginners..</h5>
              </div>
              <div className="card-body">
                <button className="btn btn bg-success">Details</button>
              </div>
            </div>
          </div>

          <div className="col-3  p-3">
            <div className="card">
              <img src={python} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Python for beginners..</h5>
              </div>
              <div className="card-body">
                <a href="#" className="card-link">
                  Deatails
                </a>
              </div>
            </div>
          </div>

          <div className="col-3  p-3">
            {" "}
            <div className="card">
              <img src={web} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Cyber security for beginners..</h5>
              </div>
              <div className="card-body">
                <a href="#" className="card-link">
                  Deatails
                </a>
              </div>
            </div>
          </div>

          <div className="col-3  p-3">
            {" "}
            <div className="card">
              <img src={cyber} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Cyber security for beginners..</h5>
              </div>
              <div className="card-body">
                <a href="#" className="card-link">
                  Deatails
                </a>
              </div>
            </div>
          </div>

          <div className="col-3  p-3">
            {" "}
            <div className="card">
              <img src={uiux} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Cyber security for beginners..</h5>
              </div>
              <div className="card-body">
                <a href="#" className="card-link">
                  Deatails
                </a>
              </div>
            </div>
          </div>

          <div className="col-3  p-3">
            {" "}
            <div className="card">
              <img src={fiver} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Cyber security for beginners..</h5>
              </div>
              <div className="card-body">
                <a href="#" className="card-link">
                  Deatails
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Courses;
