import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// import "./student.css"
import { useEffect, useState } from "react";

const Profile = () => {
  const [_id, setId] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [student, setStudent] = useState([]);

  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");

  useEffect(() => {
    (async () => await Load())();
  }, []);

  async function Load() {
    try {
      const result = await axios.get("http://localhost:8070/students/");
      setStudent(result.data[0] || {});
      console.log(result);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }
  async function save(e) {
    e.preventDefault();
    try {
      console.log("Before registration request");
      await axios.post("http://localhost:8070/student/create", {
        name: name,
        address: address,
        phone: phone,
        email: email,
      });
      console.log("After registration request");
      alert("Student Registration Successfully");
      clearFields();
      Load();
    } catch (error) {
      console.error("Error during registration:", error);
      alert(`Student Registration failed: ${error.message}`);
    }
  }
  async function update(e) {
    e.preventDefault();
    try {
      await axios.patch(
        "http://localhost:8070/students/" + student._id || _id,
        {
          _id: _id,
          name: name,
          address: address,
          phone: phone,
          email: email,
        }
      );
      alert("Student Update Successfully");
      clearFields();
      Load();
    } catch (error) {
      console.error("Error updating student:", error);
      alert(`Student Update failed: ${error.message}`);
    }
  }

  async function deleteEmployee(_id) {
    try {
      await axios.delete("http://localhost:8070/students/" + _id);
      alert("Student deleted successfully");
      clearFields();
      Load();
    } catch (error) {
      console.error("Error deleting student:", error);
      alert(`Student deletion failed: ${error.message}`);
    }
  }
  async function editEmployee(employees) {
    setName(employees.name);
    setAddress(employees.address);
    setPhone(employees.phone);
    setId(employees._id);
    setEmail(employees.email);
  }

  function clearFields() {
    setId("");
    setName("");
    setAddress("");
    setPhone("");
    setEmail("");
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post("http://localhost:8070/upload", formData);

      alert("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadMessage("Error uploading file");
    }
  };

  return (
    <>
      <div>
        <h1 className="text-center">Student Deatails</h1>
      </div>

      <div className="container mt-4 ">
        <div className="row">

          <div className="col-md-6">
            <div className="card">
              <div className="card-body row">
                {/* <h5 className="card-title">Student Information</h5> */}

                <form>
                  <div className="form-group">
                    <label htmlFor="name">Student Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">Student Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="btn-group" role="group">
                    <button className="btn btn-primary mt-4" onClick={save}>
                      Register
                    </button>
                    <button className="btn btn-warning mt-4" onClick={update}>
                      Update
                    </button>
                  </div>
                </form>
                
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="card">
              <div className="card-body row">
                <div className="col-6">
                  <p className="card-title">Student ID:</p>
                  <p className="card-title">Name:{student.name}</p>
                  <p className="card-text">Address: {student.address}</p>
                  <p className="card-text">Email: {student.email}</p>
                  <p className="card-text">Mobile: {student.phone}</p>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => editEmployee(student)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => deleteEmployee(student._id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    {file && (
                      <img
                        src={URL.createObjectURL(file)}
                        className="img-rounded-end"
                        alt="img"
                        height={100}
                        width={100}
                        style={{
                          marginTop: "10px",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      />
                    )}
                    {uploadMessage && (
                      <p style={{ marginTop: "10px" }}>{uploadMessage}</p>
                    )}
                  </div>

                  <div>
                    <input type="file" onChange={handleFileChange} />

                    <button
                      className="btn btn-success mt-2"
                      onClick={handleUpload}
                      disabled={!file}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Profile;
