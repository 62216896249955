import "./userlogin.css";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    axios
      .post("http://localhost:8070/loginUser", { username, password })
      .then((result) => {
        console.log(result);
        if (result.data === "Login Successfully") {
          navigate("/sidebar");
        }
      })
      .catch((err) => console.log(err));

    // Perform validation checks here
    if (username === "") {
      setErrorMessage("Please enter a username");
      return;
    }

    if (password === "") {
      setErrorMessage("Please enter a password");
      return;
    }

    // Clear any existing error messages
    setErrorMessage(" ");

    // Handle the login logic here (e.g., send the username and password to an API)
    // ...
  };

  return (
    // <>
    //   <div>
    //     <div className="login-form">
    //       <form onSubmit={handleFormSubmit}>
    //         <div className="form-group">
    //           <h2 className="loginHeader">User Login</h2>

    //           <input
    //             type="text"
    //             id="username"
    //             placeholder="Username"
    //             value={username}
    //             onChange={handleUsernameChange}
    //           />
    //         </div>

    //         <div className="form-group">
    //           <input
    //             type="password"
    //             id="password"
    //             placeholder="Password"
    //             value={password}
    //             onChange={handlePasswordChange}
    //           />
    //         </div>

    //         {errorMessage && (
    //           <div className="error-message">{errorMessage}</div>
    //         )}

    //         <button type="submit">Login</button>
    //       </form>

    //       <div className="vertical-line"></div>
    //     </div>
    //   </div>
    // </>
    <>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="login-form">
          <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <h2 className="loginHeader">User Login</h2>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Username
              </label>
              <input
                type="text"
                id=""
                className="form-control"
                placeholder="Enter your Username...."
                value={username}
                onChange={handleUsernameChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label visually-hidden">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Enter your Password...."
                value={password}
                onChange={handlePasswordChange}
              />
            </div>

            {errorMessage && (
              <div className="mb-3 text-danger">{errorMessage}</div>
            )}

            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</>

  );
};

export default UserLogin;
