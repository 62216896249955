import React from "react";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import axios from "axios";

const Python = () => {
  const [python,setPython] =useState([]);
  useEffect(()=>{
    axios
      .get("http://localhost:8070/myLearning")
      .then((python) => setPython(python.data))
     
      .catch((err) => console.log(err));
      
                      
  },[])
  console.log(python)

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-4 mt-5">
            <div class="card text-center vh-100 ">
             {python.map((std)=>{
              return(
                <div class="card-body ">
                <label>Course Id: {std.courseId[1]}</label>
                <label>Course Name: {std.courseName[1]}</label>
                <label>Conducted by:{std.conductedBy[1]}</label>
                <label>Duration: {std.duration}</label><br />
                <label>No of vedioes: {std.noOfLessons}</label>
                <label>Payment fee: {std.paymentFee[1]}</label>
                <label>Payment Status: {std.paymentStatus[1]}</label>
                <label>Payment date:{std.payedDate[1]}</label>
              </div>
              )
             })}
            </div>
          </div>
          <div className="col-8 mt-5">
            <div class="card vh-100 ">
              <div class="card-body">
            <Carousel>
              {python[0]?.python.map((videoUrl, index) => (
                <Carousel.Item key={index}>
                  <ReactPlayer
                    url={videoUrl}
                    width="100%"
                    pip={false}
                    controls={false}
                    playing={false}
                  />
                  <Carousel.Caption>
                    
                    <h3></h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Python;
