import useState from "react";
import "./Sidebar.css";
import Logo from "../../Assets/Evotech.png";
import { SideBarData } from "../Data.js/Data";
import { Link } from "react-router-dom";
import Learn from "../Video/Learn.mp4";

const Sidebar = () => {
  return (
    // <>

    // <div className="Sidebar">
    //   <div className="headertitle">
    //     <div className="evotectlogo"></div>
    //     <div className="tex"></div>
    //     <div className="dashboardtitle">
    //       <h1>
    //         LEARNING MANAGEMENT SYSTEM | EVOTEC HIGHER EDUCATIONAL INSTITUTE
    //       </h1>
    //     </div>
    //   </div>

    //   <div className="menudrop">
    //     <div className="left">
    //       <div className="hllspace"></div>

          // <Link to="/">
          //   <div className="onetex">
          //     <button className="btnone">Home</button>
          //   </div>
          // </Link>

          // <div className="hllspace"></div>

          // <Link to="/ourcourses">
          //   <div className="onetex">
          //     <button className="btnone">Our Courses</button>
          //   </div>
          // </Link>

          // <div className="hllspace"></div>

          // <Link to="/mylearning">
          //   <div className="onetex">
          //     <button className="btnone">My Learning</button>
          //   </div>
          // </Link>

          // <div className="hllspace"></div>

          // <Link to="/profile">
          //   <div className="onetex">
          //     <button className="btnone">My Profile</button>
          //   </div>
          // </Link>

    //       <div className="hllspace"></div>
    //     </div>

    //     {/* <div className="right">
    //       <video src={Learn} autoPlay loop muted />
    //     </div> */}
    //   </div>
    // </div>

    // </>


    <>
       <div className="container-fluid p-0">
       
        <div className="row">
        <div className="header">
                  <h1 className="position-fixed"> LEARNING MANAGEMENT SYSTEM <br /> EVOTEC HIGHER EDUCATIONAL INSTITUTE</h1>
                  
                  </div>
        <video src={Learn} autoPlay loop muted/> 
          <div className="col-3 position-fixed ">
                  <div className="ms-4">
                    <img src={Logo} alt=""/>
                  </div>
                  <div className="bg-green text-white ">

                  <Link to="/">
            <div className="onetex">
              <button className="btnone">Home</button>
            </div>
          </Link>

          <div className="hllspace"></div>

          <Link to="/ourcourses">
            <div className="onetex">
              <button className="btnone">Our Courses</button>
            </div>
          </Link>

          <div className="hllspace"></div>

          <Link to="/mylearning">
            <div className="onetex">
              <button className="btnone">My Learning</button>
            </div>
          </Link>

          <div className="hllspace"></div>

          <Link to="/profile">
            <div className="onetex">
              <button className="btnone">My Profile</button>
            </div>
          </Link>
                    
                  </div>
                  
          </div>
          <div className="col-9">
          
                  
                  
          </div>
          
          
        </div>
        
       </div> 
      
    </>
  );
};

export default Sidebar;
